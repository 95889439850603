import React, { useEffect } from 'react'
import { PopupButton } from '@typeform/embed-react'
import { srcSetProps, sanityImageUrl, clickTrack } from '../../../utils/format'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { Link } from 'gatsby'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ header }) => {
  if (!header) return null

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
  }, [])

  const scrollToTestosteroneProtocols = () => {
    gsap.to(window, {
      duration: 1.5,
      scrollTo: document.getElementById('testosterone-protocols'),
    })
  }

  const scrollToOtherProtocols = () => {
    gsap.to(window, {
      duration: 1.5,
      scrollTo: document.getElementById('other-protocols'),
    })
  }

  return (
    <div className={styles.header}>
      <div className={styles.image}>
        <img className={styles.imageSrc} {...srcSetProps(sanityImageUrl(header.imageBlack))} alt={header.imageBlack?.caption} />
      </div>
      <div className={styles.wrap}>
        <div className={styles.fh}>
          <div className={styles.text}>
            <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: header.title }} />
            <p className={styles.description}>{header.description}</p>
            <div className={styles.btns}>
              <PopupButton
                id={header.buttonUrl1.replace('https://quiz/', '')}
                as="a"
                onReady={() => clickTrack(header.buttonUrlText)}
                transitiveSearchParams={true}
                className={styles.btnsBlue}
              >
                {header.buttonText1}
              </PopupButton>
              {header.buttonText2 && (
                <a onClick={scrollToOtherProtocols} className={styles.btnsDefault}>{header.buttonText2}</a>
              )}
            </div>         
          </div>
          <div className={styles.mobile}>
            <img className={styles.mobileImage} {...srcSetProps(sanityImageUrl(header.imageBlackMobile))} alt={header.imageBlackMobile?.caption} />
          </div>
          <div className={styles.bottom}>
            {header.links &&
              header.links.map(link => (
                <Link
                  to={link.url}
                  className={styles.bottomLink}
                  key={link.id}
                  dangerouslySetInnerHTML={{ __html: link.title }}
                />
              ))}
          </div> 
        </div>        
      </div>      
    </div>
  )
}
