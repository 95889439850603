import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/format'
import ProductSchema from '../components/Shared/Schema/Product'
import FaqSchema from '../components/Shared/Schema/Faq'
import Header from '../components/TestosteroneLinePage/Header'
import Stats from "../components/TestosteroneLinePage/Stats"
import Protocols from "../components/TestosteroneLinePage/Protocols"
import Articles from "../components/TestosteroneLinePage/Articles"
import Doctors from "../components/TestosteroneLinePage/Doctors"
import Levels from "../components/TestosteroneLinePage/Levels"
import Blocks from "../components/TestosteroneLinePage/Blocks"
import OtherProtocols from "../components/TestosteroneLinePage/OtherProtocols"
import Benefits from "../components/TestosteroneLinePage/Benefits"
import Faq from '../components/TestosteroneLinePage/Faq'
import FoodDrug from '../components/TestosteroneLinePage/FoodDrug'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Stats stats={data.stats} />,
      priority: data.stats?.priority || 99
    },
    {
      render: <Doctors doctors={data.doctors} />,
      priority: data.doctors?.priority || 99
    },    
    {
      render: <Protocols protocols={data.protocols} />,
      priority: data.protocols?.priority || 99
    },
    {
      render: <Articles articles={data.articles} />,
      priority: data.articles?.priority || 99
    },
    {
      render: <OtherProtocols otherProtocols={data.otherProtocols} />,
      priority: data.otherProtocols?.priority || 99
    },
    {
      render: <Blocks blocks={data.blocks} />,
      priority: data.blocks?.priority || 99
    },
    {
      render: <Levels levels={data.levels} />,
      priority: data.levels?.priority || 99
    },
    {
      render: <Benefits benefits={data.benefits} />,
      priority: data.benefits?.priority || 99
    },
    {
      render: <Faq faq={data.faq} />,
      priority: data.faq?.priority || 99
    },
    {
      render: <FoodDrug foodDrug={data.foodDrug} />,
      priority: data.foodDrug?.priority || 99
    }
  ].sort(sortComponents)

  return (
    <Layout data={data} signUpUrl={data.header?.buttonUrl} signUpText={data.header?.buttonText}>
      <SEO {...data.seo} />
      {data.productSchemas && data.productSchemas.map(schema => <ProductSchema {...schema} />)}
      {(data?.faq?.faqList?.items || data?.faq?.items) && (
        <FaqSchema questions={data?.faq?.faqList?.items || data?.faq?.items} />
      )}
      <Header header={data.header} />
      {components.map(component => component.render)}
    </Layout>
  )
}
