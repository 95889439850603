import React from 'react'
import { sanityImageUrl, urlWithSearchParamsHandler } from '../../../utils/format'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ protocol }) => (
  <div className={styles.item}>
    <div className={styles.itemTop}>
      <Link to={protocol.learnMoreButtonUrl} className={styles.imageBox}>
        {protocol.tag && <span className={styles.tag}>{protocol.tag}</span>}
        <img src={sanityImageUrl(protocol.imageVertical)} className={styles.image} alt={protocol.imageVertical?.caption} />
      </Link>

      <div className={styles.itemWrap}>
        <p className={styles.name}>{protocol.name}</p>
        <p className={styles.description}>{protocol.descriptionShort}</p>
      </div>
    </div>

    <div className={styles.itemBottom}>
      <p className={styles.price}>Starting at <span>{protocol.price}</span>/mo</p>
      <div className={styles.buttons}>
        <Link className={styles.buttonsMore} to={protocol.learnMoreButtonUrl}>
          {protocol.learnMoreButtonText}
        </Link>
        <a
          href={protocol.getStartedButtonUrl}
          onClick={urlWithSearchParamsHandler}
          className={styles.buttonsStarted}
        >Start Treatment</a>
      </div>
    </div>
  </div>
)